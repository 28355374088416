<template>
  <div
    :class="[
      'text-center',
      theme === 'dark' ? 'py-2xl bg-charcoal-default text-white' : 'my-2xl bg-transparent text-charcoal-default',
    ]"
  >
    <Heading size="md" class="mb-sm text-center px-xs" align="center">
      {{ title }}
    </Heading>
    <div class="mb-xs px-xs md:px-xl mx-auto" v-html="description" />

    <LazySlider
      v-if="cards?.length > 0"
      :space-between="theme === 'dark' ? 24 : 16"
      :draggable="true"
      :autoplay="false"
      :class="['md:px-md my-lg mx-auto content-container', cards.length <= 3 && 'lg:w-4/5']"
      :breakpoints="breakpoints"
      :items="cards"
    >
      <swiper-slide
        v-for="(card, index) in cards"
        :key="index"
        ref="slides"
        class="rounded-sm first-of-type:pl-2xs last-of-type:pr-2xs"
      >
        <NuxtLink :to="card.link?.href" :class="['shadow-xs block', theme !== 'dark' && 'm-3xs']">
          <NuxtImg
            v-if="card.image"
            :src="card.image"
            loading="lazy"
            provider="cloudflare"
            sizes="lg:510px xl:820px"
            class="object-cover w-full h-[20rem] sm:h-[25rem] rounded-t-sm"
          />
          <div
            :class="[
              'px-2xs py-md text-charcoal-default bg-white',
              {
                'h-[100px]': card.title && !card.description,
                'h-[120px]': (card.description && !card.title) || card.title.split(' ').length > 5,
                'h-[150px]': card.title && card.description,
                'h-[180px]': card.description.split(' ').length > 25,
              },
            ]"
          >
            <Heading size="xxs" class="text-charcoal-default text-center">
              {{ card.title }}
            </Heading>
            <Text size="sm" weight="semi" class="text-center mt-2xs text-sm">
              {{ card.description }}
            </Text>
          </div>
        </NuxtLink>
      </swiper-slide>
    </LazySlider>
  </div>
</template>

<script lang="ts" setup>
import { SwiperSlide } from "swiper/vue";
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useFeaturedCardsCarousel } from "../../../composables/UseContent/useFeaturedCardsCarousel";
const { component, page } = defineProps<{ component: Component; page: Page }>();

const { breakpoints, cards, title, description, theme } = useFeaturedCardsCarousel(page, component);
</script>
