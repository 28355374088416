<template>
  <div
    v-if="articles?.length > 0"
    :class="['my-md md:my-xl ui-bg-transparent ui-text-charcoal-default content-container']"
  >
    <section :class="['pl-xs', articles.length <= 3 && 'lg:w-4/5 mx-auto']">
      <Heading size="sm">
        {{ title }}
        <NuxtLink :to="seeAll.link" class="text-base font-semi text-mkm-blue-light ml-xs">
          {{ seeAll.label }}
        </NuxtLink>
      </Heading>
    </section>
    <LazySlider
      :space-between="16"
      :draggable="true"
      :autoplay="false"
      :class="['my-md mx-auto', articles.length <= 3 && 'lg:w-4/5']"
      :breakpoints="breakpoints"
      :items="articles"
    >
      <swiper-slide v-for="(article, index) in articles" :key="index" class="pl-xs last-of-type:pr-xs">
        <BlogPost
          :article="{
            title: article.displayName,
            category: article?.multiselect?.selectionValues,
            image: article.image,
            date: formatArticleDate(article.date),
            link: getArticleLink(article),
            meta_description: article.meta_description,
            readingTime: article.readingTime,
            linkComponent: NuxtLink,
          }"
          direction="col"
        />
      </swiper-slide>
    </LazySlider>
  </div>
</template>

<script lang="ts" setup>
import { SwiperSlide } from "swiper/vue";
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useArticleList, formatArticleDate, getArticleLink } from "../../../composables/UseContent/useArticleList";
import BlogPost from "../../../../../packages/blueprint-ui/src/components/BlogPost/BlogPost.vue";

const NuxtLink = resolveComponent("NuxtLink");

const { component, page } = defineProps<{ component: Component; page: Page }>();

const { articles, breakpoints, title, seeAll } = useArticleList(page, component);
</script>
