<template>
  <section
    v-if="patterns?.length > 0"
    class="bg-white max-w-[1540px] mx-auto py-lg px-xs sm:py-3xl sm:px-md text-charcoal-default"
  >
    <div class="mb-md">
      <Heading size="md" class="text-center mb-2xs sm:mb-xs"> {{ heading }} </Heading>

      <Heading size="xs" class="text-center my-xs sm:mb-xs">
        {{ subHeading }}
      </Heading>

      <Text class="text-charcoal-default text-center my-2xs sm:my-xs"> {{ intro }} </Text>
    </div>
    <div class="flex justify-center items-start gap-2xs sm:gap-[1%] flex-wrap">
      <NuxtLink
        v-for="(pattern, index) in patterns"
        :to="pattern.link.href"
        :key="index"
        class="w-[22%] sm:w-[11.25%] mb-sm"
      >
        <NuxtImg :src="pattern.image" :alt="pattern.title" class="object-cover w-full" />
        <Text class="mt-2xs text-center" size="sm" weight="semi">
          {{ pattern.title }}
        </Text>
      </NuxtLink>
    </div>

    <Button class="mx-auto mt-sm" size="md" variant="secondary" v-if="cta.href && cta.text">
      <NuxtLink :to="cta.href">
        {{ cta.text }}
      </NuxtLink>
    </Button>
  </section>
</template>

<script lang="ts" setup>
import type { Component, Page } from "@bloomreach/spa-sdk";
import { usePatternList } from "../../composables/UseContent/usePatternList";
const { component, page } = defineProps<{ component: Component; page: Page }>();

const { heading, subHeading, intro, patterns, cta } = usePatternList(page, component);
</script>
