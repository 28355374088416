<template>
  <Stack
    direction="col"
    gap="sm"
    :breakpoints="{
      gap: {
        md: 'lg',
      },
    }"
  >
    <BlogPost
      v-for="(article, index) in posts"
      :key="index"
      :article="{
        title: article.title,
        category: article.category,
        image: getSrcOf(article.image),
        date: formatArticleDate(article.date),
        link: getArticleLink(article),
        meta_description: article.meta_description,
        readingTime: article.readingTime,
        linkComponent: NuxtLink,
      }"
      direction="row"
    />
  </Stack>
</template>

<script setup lang="ts">
import type { Page } from "@bloomreach/spa-sdk";
import { useContentHelpers } from "../../composables/UseContent/helpers";
import { formatArticleDate, getArticleLink } from "../../composables/UseContent/useArticleList";

const NuxtLink = resolveComponent("NuxtLink");

const { component, page } = defineProps<{ component: any; page: Page }>();
const { getSrcOf } = useContentHelpers(page, component);

const posts = (page.getContent(component.getContentReference()) as any).data.articles.map(
  (it: any) => page.getContent(it)?.getData(),
);
</script>
