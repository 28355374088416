import ArticleList from "~/components/Content/Carousel/ArticleList.vue";
import BlogHero from "~/components/Content/BlogHero.vue";
import ChevronBanner from "~/components/Content/Banner/ChevronBanner.vue";
import CircledItemsList from "~/components/Content/CircledItems/CircledItemsList.vue";
import CreateAccount from "~/components/Page/Login/CreateAccount.vue";
import CustomerTestimonials from "~/components/Content/CustomerTestimonials.vue";
import DepartmentRolesList from "~/components/Content/DepartmentRolesList.vue";
import DoubleBanner from "~/components/Content/Banner/DoubleBanner.vue";
import EditorialBlock from "~/components/Content/EditorialBlock.vue";
import EditorialCards from "~/components/Content/EditorialCards.vue";
import FeaturedArticles from "~/components/Content/FeaturedArticles.vue";
import FeaturedCardsCarousel from "~/components/Content/Carousel/FeaturedCardsCarousel.vue";
import Footer from "~/components/Global/Footer/Footer.vue";
import FooterSocialLinks from "~/components/Global/Footer/FooterSocialLinks.vue";
import FooterTermsMenu from "~/components/Global/Footer/FooterTermsMenu.vue";
import Gallery from "~/components/Content/Gallery.vue";
import Header from "~/components/Global/Header/Header.vue";
import HeaderBar from "~/components/Content/USP/HeaderBar.vue";
import HelpTitleText from "~/components/Content/HelpTitleText.vue";
import HeroTabulatedBanner from "~/components/Content/Banner/HeroTabulatedBanner.vue";
import IframeContainer from "~/components/Content/IframeContainer.vue";
import ImageContainer from "~/components/Content/ImageContainer.vue";
import ImageWithLinkSection from "~/components/Content/ImageWithLinkSection.vue";
import ImageWithTextSection from "~/components/Content/ImageWithTextSection.vue";
import KitchenList from "~/components/Content/KitchenList.vue";
import Video from "~/components/Content/Video.vue";
import LoginInfoPanel from "~/components/Page/Login/LoginInfoPanel.vue";
import LogoCarousel from "~/components/Content/LogoCarousel.vue";
import MarketingBanner from "~/components/Content/Banner/MarketingBanner.vue";
import Menu from "~/components/Global/Menu/Menu.vue";
import PageBlocks from "~/components/Content/USP/PageBlocks.vue";
import PatternList from "~/components/Content/PatternList.vue";
import HeaderGuestMenu from "~/components/Global/Header/HeaderGuestMenu.vue";
import SearchResults from "~/components/Page/Search/SearchResults.vue";
import axios from "axios";
import {
  initialize as initializeSdk,
  type Configuration,
  type Page,
  TYPE_CONTAINER_ITEM_UNDEFINED,
} from "@bloomreach/spa-sdk";

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const link = (data: any, page: any) => {
  if (data?.internalLink) {
    return {
      type: "internal",
      href: data.internalLink || "",
    };
  }
  if (data?.link) {
    const l = data.link.startsWith("http") ? data.link : "//" + data.link;
    return {
      href: l.replace("///", "/") || "",
      target: "_blank",
    };
  }
  if (data?.cmslink?.$ref) {
    return {
      type: "internal",
      href: page.getContent(data.cmslink.$ref).model?.links?.site?.href || "",
    };
  }
  return {
    type: "internal",
    href: "",
  };
};

export const componentMapping = {
  "Vsf Gallery": Gallery,
  "tc-menu": FooterTermsMenu,
  ArticleList: ArticleList,
  BlogHero: BlogHero,
  ChevronBanner: ChevronBanner,
  ChevronImageWithRotatingText: CustomerTestimonials,
  CircleListComponent: CircledItemsList,
  CreateAccountRationale: CreateAccount,
  DepartmentRolesList: DepartmentRolesList,
  DoubleBanner: DoubleBanner,
  EditorialCards: EditorialCards,
  FeaturedArticles: FeaturedArticles,
  FeaturedCardsCarousel: FeaturedCardsCarousel,
  HelpTitleText,
  HeroTabulatedCarousel: HeroTabulatedBanner,
  ImageWithLink: ImageWithLinkSection,
  KitchenList: KitchenList,
  LoginRationale: LoginInfoPanel,
  RegistrationRationale: CreateAccount,
  LogoCarousel: LogoCarousel,
  MKMIframe: IframeContainer,
  MarketingBannerCombined: MarketingBanner,
  PatternListComponent: PatternList,
  USPBlock: PageBlocks,
  USPHeaderBar: HeaderBar,
  yourMkmGuestMenu: HeaderGuestMenu,
  TextSearchResults: SearchResults,
  VsfEditorial: EditorialBlock,
  footer: Footer,
  imageWithText: ImageWithTextSection,
  menu: Header,
  responsiveImageContainer: ImageContainer,
  shopByRoomMenu: Menu,
  social: FooterSocialLinks,
  [TYPE_CONTAINER_ITEM_UNDEFINED]: "",
  MKMVideo: Video,
};

const useBloomreach = () => {
  const route = useRoute();
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();
  interface PageContent extends Page {
    root?: Record<string, any>;
  }

  interface Visitor {
    /**
     * The visitor identifier.
     */
    id: string | null;
    /**
     * An HTTP-header using to pass the visitor id to the Page Model API.
     */
    header: string | null;
    /**
     * New visitor flag.
     */
    new?: boolean | null;
  }

  const content = useState<Page | Ref<PageContent> | null>(`content`, () => null);
  const visitor = useState<Visitor | null>("visitor", () => null);
  const isNavigating = useState<boolean>("navigating", () => false);

  const configuration: Configuration = reactive({
    endpoint: route.query.endpoint || `${config.public.BLOOMREACH_CMS_ENDPOINT}/pages`,
    httpClient: axios,
    path: route.fullPath,
    NBRMode: true,
  });

  const containsPageNotFound = (obj: any): boolean => {
    if (obj?.name === "page-not-found") {
      return true;
    }
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null && containsPageNotFound(obj[key])) {
        return true;
      }
    }
    return false;
  };

  async function initialize() {
    const event = useRequestEvent();
    const start = performance.now();

    if (!checkIfPageExistInBR(route)) return;

    const { data: pageData } = await useAsyncData(async (context) => {
      const page = await initializeSdk({
        ...configuration,
        httpClient: axios,
        visitor: undefined,
        request: context?.ssrContext?.event?.node?.req,
      });

      return { page: page.toJSON() };
    });

    if (containsPageNotFound(pageData.value) && !route.path.includes("/explore")) {
      content.value = null;
      throw createError({
        statusCode: 404,
        message: "page not found",
        statusMessage: "no page",
        fatal: false,
      });
    }

    content.value = pageData.value?.page ?? null;

    const end = performance.now(); // End timing
    const duration = end - start;

    const existingHeader = event && event.node.res.getHeader("Server-Timing");
    const newTiming = `bloomreachContent;dur=${duration}`;

    // Append new timing to existing header or set new header
    if (existingHeader) {
      event.node.res.setHeader("Server-Timing", `${existingHeader}, ${newTiming}`);
    } else {
      event && event.node.res.setHeader("Server-Timing", newTiming);
    }
  }

  watch(
    () => route.path,
    async () => {
      if (
        (route.path.includes("/category") && route.params.cat2?.length > 0) ||
        (route.path.includes("/category") && route.params.cat3?.length > 0) ||
        route.path.includes("/product") ||
        route.path.includes("/explore")
      )
        return;

      await nextTick(async () => {
        content.value = null;
        configuration.path = route.path;

        nuxtApp.hook("page:start", () => {
          isNavigating.value = true;
        });

        nuxtApp.hook("page:finish", () => {
          isNavigating.value = false;
        });
      });
    },
    { deep: true },
  );

  return {
    initialize,
    content,
    configuration,
    componentMapping,
    isNavigating,
  };
};

export default useBloomreach;
