<template>
  <ImageWithText
    v-if="title"
    :content="{
      title: title,
      description: description || 'This is an image with text section.',
      imageUrl: image || 'https://via.placeholder.com/328x232',
      link: link,
      alt: title,
      backgroundColor: backgroundColor,
      imagePosition: orientation,
    }"
  />
</template>

<script lang="ts" setup>
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useImageWithText } from "../../composables/UseContent/useImageWithText";
const { component, page } = defineProps<{ component: Component; page: Page }>();

const { title, description, image, link, orientation, backgroundColor } = useImageWithText(page, component);
</script>
