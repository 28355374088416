<template>
  <Stack
    direction="row"
    justify="center"
    align="center"
    class="py-lg"
    gap="2xs"
    :breakpoints="{
      gap: { sm: 'md' },
    }"
    v-if="imageOne || imageTwo || imageThree"
  >
    <a v-if="imageOne && linkOne" :href="linkOne">
      <img :src="imageOne" :alt="title" class="h-[50px] sm:h-[88px] object-cover" />
    </a>

    <a v-if="imageTwo && linkTwo" :href="linkTwo">
      <img :src="imageTwo" :alt="title" class="h-[50px] sm:h-[88px] object-cover" />
    </a>

    <a v-if="imageThree && linkThree" :href="linkThree">
      <img :src="imageThree" :alt="title" class="h-[50px] sm:h-[88px] object-cover" />
    </a>
  </Stack>
</template>

<script lang="ts" setup>
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useImageWithLink } from "../../composables/UseContent/useImageWithLink";
const { component, page } = defineProps<{ component: Component; page: Page }>();

const { title, linkOne, linkTwo, linkThree, imageOne, imageTwo, imageThree } = useImageWithLink(page, component);
</script>
