<template>
  <Stack direction="col" gap="2xs">
    <Text v-if="helpTitle" weight="bold">{{ helpTitle }}</Text>
    <div v-if="helpDescription" v-html="helpDescription" class="help-description" />
  </Stack>
</template>

<script lang="ts" setup>
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useHelpTitleText } from "~/composables/UseContent/useHelpTitleText";

const { component, page } = defineProps<{ component: Component; page: Page }>();
const { helpTitle, helpDescription } = useHelpTitleText(page, component);
</script>

<style>
.help-description a {
  @apply text-mkm-blue-light;
}
</style>
