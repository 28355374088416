<template>
  <section
    v-if="title || description"
    :style="{
      background: 'linear-gradient(to right, #fc0 50%, #143981 50%)',
    }"
  >
    <div class="flex flex-col sm:flex-row overflow-hidden content-container">
      <div class="w-full sm:w-3/5">
        <div :class="['p-sm sm:pr-none sm:px-md md:pt-xl min-h-full bg-mkm-yellow-default']">
          <h2
            :class="[
              'uppercase font-display -tracking-[0.05em] [ text-[2rem] leading-[2rem] md:text-[2.5rem] md:leading-[2.5rem] lg:text-[3.5rem] lg:leading-[3.5rem] ]',
              theme === 'Blue' ? 'text-mkm-yellow-default' : 'text-white',
            ]"
          >
            {{ title }}
          </h2>

          <p
            :class="[
              '[ text-sm leading-sm md:text-base md:leading-base ] my-xs font-semi',
              theme === 'Blue' ? 'text-white' : 'text-mkm-blue-default',
            ]"
          >
            {{ description }}
          </p>
        </div>
      </div>
      <div
        :class="[
          'relative [ w-full sm:w-1/2 ] [ pb-2xs sm:pb-none sm:pr-2xs ]',
          theme === 'Blue' ? 'bg-mkm-yellow-default' : 'bg-mkm-blue-default',
          'before:hidden before:sm:block before:absolute before:left-none before:bottom-none before:border-[2rem] before:border-b-transparent before:border-r-transparent before:border-y-[250px] before:border-x-[64px] before:md:border-x-[100px]',
          theme === 'Blue' ? 'before:border-mkm-blue-default' : 'before:border-mkm-yellow-default',
          'after:hidden after:sm:block after:absolute after:right-2xs sm:after:bottom-none after:border-[2rem] after:border-t-transparent after:border-l-transparent after:border-y-[250px] after:border-x-[64px] after:md:border-x-[100px]',
          theme === 'Blue' ? 'after:border-mkm-yellow-default' : 'after:border-mkm-blue-default',
        ]"
      >
        <section
          class="p-2xs sm:p-sm md:p-lg skew-x-[0deg] sm:skew-x-[-14.25deg] md:skew-x-[-21.75deg] bg-white opacity-50 z-10 absolute [ w-full sm:w-fit h-sm sm:h-full ] left-none sm:left-[2.2rem] md:left-[4rem]"
        />
        <NuxtImg
          v-if="image"
          :src="image"
          :alt="title"
          :class="['object-cover mx-auto w-full h-[160px] sm:h-full lg:h-[320px]']"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useChevronBanner } from "../../../composables/UseContent/useChevronBanner";

const { component, page } = defineProps<{ component: Component; page: Page }>();
let { title, theme, description, image } = useChevronBanner(page, component);
</script>
