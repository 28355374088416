<template>
  <section class="my-xl px-xs lg:my-3xl lg:px-md max-w-[1720px] mx-auto">
    <Heading v-if="title" size="md" class="text-center text-charcoal-default" align="center"> {{ title }} </Heading>
    <Text class="mt-sm text-center mx-auto" align="center" v-if="description">
      {{ description }}
    </Text>
    <Stack
      tag="div"
      align="center"
      justify="between"
      gap="xl"
      class="ui-text-mkm-blue-default py-xl"
      direction="col"
      :breakpoints="{ direction: { md: 'row' }, align: { md: 'start' } }"
    >
      <div
        v-for="(usp, idx) in usps"
        :key="idx"
        class="ui-text-center ui-flex ui-flex-col ui-items-center lg:w-[12.25rem]"
      >
        <SvgIcon :name="useBloomreachIconMapping(usp.icon)" :width="64" :height="64" class="p-2xs md:p-none" />
        <Heading size="xs" class="text-center mt-sm md:mt-md mb-xs">{{ usp.name }}</Heading>
        <Text weight="semi" size="sm" class="text-center ui-text-charcoal-default mb-xs">{{ usp.description }}</Text>
      </div>
    </Stack>

    <Button size="md" :variant="cta.color" class="mx-auto" v-if="cta.link.href">
      <Link tag="a" class="mx-auto" :href="cta.link.href"> {{ cta.text }} </Link>
    </Button>
  </section>
</template>

<script lang="ts" setup>
import type { Component, Page } from "@bloomreach/spa-sdk";
import Link from "../../../../../packages/blueprint-ui/src/components/Link/Link.vue";
import { usePageBlocks } from "../../../composables/UseContent/usePageBlocks";
import useBloomreachIconMapping from "~/composables/useIconMappings";

const { component, page } = defineProps<{ component: Component; page: Page }>();
const { title, description, usps, cta } = usePageBlocks(page, component);
</script>
