<template>
  <Stack
    v-show="searchResults"
    tag="div"
    direction="col"
    col="md"
    class="absolute bottom-[-545px] left-none bg-white w-full h-[545px] md:bottom-[-550px] z-50 rounded-b-sm sm:rounded-sm shadow-sm py-xs overflow-y-scroll"
  >
    <Stack tag="ul" direction="col" gap="3xs" class="px-sm w-full min-h-[164px]">
      <Stack
        tag="li"
        justify="between"
        align="center"
        v-for="(suggestionTerm, index) in suggestions"
        :key="`${suggestionTerm}:${index}`"
        class="w-full text-grey-default"
        data-fs="searchTextResults"
      >
        <NuxtLink
          :to="`/search?q=${suggestionTerm}`"
          @click="closeSearchResultsUponClick"
          weight="semi"
          class="text-charcoal-light"
          v-html="highlight(String(suggestionTerm), query)"
        />

        <button @click="handleUpdateQuery(String(suggestionTerm))">
          <span class="sr-only">{{ suggestionTerm }}</span>
          <Icon name="arrow-top-left" :size="14" filled />
        </button>
      </Stack>
    </Stack>

    <Stack tag="div" direction="col" gap="none" class="w-full">
      <Text class="px-sm pb-sm" weight="bold" v-if="items && items.length > 0">These products may be of interest</Text>

      <Stack
        tag="ul"
        direction="col"
        gap="none"
        class="w-full"
        v-for="(item, index) in items"
        :key="`${item.itemId}:${index}`"
      >
        <li class="w-full" data-fs="searchCard">
          <NuxtLink :to="generateProductUrl(item.displayName, item.itemId.id)" @click="closeSearchResultsUponClick">
            <SearchCard
              :content="{
                image: item.imageSet.original.link.href ?? '/product/placeholder-product.png',
                title: item.displayName,
                subtitle: `Product code: ${item.itemId.id}`,
                priceEach: 1,
                quantity: 1,
                total: isVatIncluded ? Number(updatePrice(item)?.price_inc_vat) : Number(updatePrice(item)?.price),
                vat: isVatIncluded,
                hasVat: isVatIncluded,
                unit: '',
              }"
            />
          </NuxtLink>
        </li>
      </Stack>
    </Stack>
  </Stack>
</template>

<script lang="ts" setup>
import type { HeaderSearchResultsProps } from "./headerTypes";
import slugify from "slugify";

const { items, query } = defineProps<HeaderSearchResultsProps>();

const emit = defineEmits<{
  (event: "update:query", value: string): void;
}>();

const { searchResults, setSearchResults, isVatIncluded, setMobileSearch } = useUIState();
const { findPriceObjById } = usePrices();
const { selectedBranch } = useBranches();
const { user } = useUser();

const handleUpdateQuery = (query: string) => {
  emit("update:query", query);
};

const updatePrice = computed(() => {
  return (item: Item) => {
    if (!selectedBranch) {
      return { price: undefined, price_inc_vat: undefined };
    }

    if (user.value) {
      const price = findPriceObjById(item.itemId.id)?.price;
      const price_inc_vat = findPriceObjById(item.itemId.id)?.price_inc_vat;
      return { price, price_inc_vat };
    }

    return item.customAttrs?.reduce<{ price?: string; price_inc_vat?: string }>(
      (acc, { name, values }) => {
        if (name === "price" || name === "price_inc_vat") {
          acc[name as keyof typeof acc] = values[0] as never;
        }
        return acc;
      },
      { price: undefined, price_inc_vat: undefined },
    );
  };
});

const closeSearchResultsUponClick = () => {
  setSearchResults(false);
  setMobileSearch(false);
};

const generateProductUrl = (displayName: string, sku: string) =>
  `/product/${slugify(displayName, {
    lower: true,
  })}-${sku}`;

const highlight = (words: string, query: string) => {
  const lowerCaseWords = words.toLowerCase();
  const lowerCaseQuery = query.toLowerCase();
  const regex = new RegExp(lowerCaseQuery, "gi");
  return lowerCaseWords.replace(regex, `<strong class="font-bold text-charcoal-default">${lowerCaseQuery}</strong>`);
};
</script>
