<template>
  <section :class="['max-w-[1540px] mx-auto my-3xl overflow-hidden', themeConfig[theme].background]">
    <Stack
      class="relative"
      direction="col"
      gap="none"
      align="center"
      :breakpoints="{
        direction: {
          sm: 'row',
        },
        align: {
          sm: isLite ? 'center' : 'end',
        },
      }"
    >
      <div
        v-if="isLite"
        :class="[
          'hidden sm:block absolute top-none right-none h-full w-full sm:w-3/5 -z-0',
          themeConfig[theme].iconColor,
        ]"
        v-html="getIcon('gradient-chevrons', 64)"
      />

      <div class="p-sm pb-xs sm:p-md sm:pb-2xs sm:pr-2xs text-center sm:text-left">
        <h2
          :class="[
            'uppercase font-display -tracking-[0.05em] mb-xs text-[2.25rem] leading-[2.25rem] md:text-[3rem] md:leading-[3rem] lg:text-[4rem] lg:leading-[4rem]',
            themeConfig[theme].title,
          ]"
        >
          {{ heading }}
        </h2>

        <p
          v-if="!isLite"
          :class="[
            'text-[14px] leading-xs sm:text-lg sm:leading-md lg:text-2xl lg:leading-lg mb-sm md:mb-md font-semibold',
            themeConfig[theme].text,
          ]"
        >
          {{ bodyCopy }}
        </p>
        <Button
          v-if="!isLite && buttonText"
          size="sm"
          :variant="themeConfig[theme].buttonVariant"
          class="min-w-full sm:min-w-fit text-center sm:mb-xs"
        >
          <NuxtLink :to="buttonLink" class="mx-auto text-md p-[2px]"> {{ buttonText }} </NuxtLink>
        </Button>
      </div>

      <NuxtImg
        v-if="image"
        :src="image"
        :alt="heading"
        :class="['w-full mx-auto z-10', isLite ? 'sm:w-[300px] md:w-[420px]' : 'sm:max-w-[485px]']"
      />

      <div v-if="isLite" class="min-w-full sm:min-w-fit px-md md:pl-2xs z-10">
        <Button :variant="themeConfig[theme].buttonVariant" size="sm" class="min-w-full sm:min-w-fit text-center my-sm">
          <NuxtLink :to="buttonLink" class="mx-auto text-md p-[2px]">
            {{ buttonText }}
          </NuxtLink>
        </Button>
      </div>
    </Stack>
  </section>
</template>

<script setup lang="ts">
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useMarketingBanner, themeConfig } from "../../../composables/UseContent/useMarketingBanner";
import { getIcon } from "../../../../../packages/blueprint-ui/src/components/Icon/getIcon";

const { component, page } = defineProps<{ component: Component; page: Page }>();
let { heading, isLite, bodyCopy, theme, image, buttonLink, buttonText } = useMarketingBanner(page, component);
</script>
