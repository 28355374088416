<template>
  <div
    v-if="content?.[0]?.content?.value"
    v-html="content[0].content.value"
    class="p-md md:p-xl mb-md rich-text content-container"
  />
</template>

<script lang="ts" setup>
import { useContentHelpers } from "../../composables/UseContent/helpers";
import type { Component, Page } from "@bloomreach/spa-sdk";

const { component, page } = defineProps<{ component: Component; page: Page }>();
const { getContentOf } = useContentHelpers(page, component);

const content = Object.values(component.getModels()).map((it) => {
  const document = getContentOf(it);
  return {
    ...document?.getData(),
    document,
    page,
  };
});
</script>
