<template>
  <section class="my-xl md:px-xl">
    <NuxtLink :to="link.href">
      <img :src="mobileImage" :alt="altTag" loading="lazy" class="block md:hidden mx-auto" />
      <img :src="desktopImage" :alt="altTag" loading="lazy" class="hidden md:block mx-auto" />
    </NuxtLink>
  </section>
</template>

<script setup lang="ts">
import type { ContainerItem, Page } from "@bloomreach/spa-sdk";
import { useImageContainer } from "../../composables/UseContent/useImageContainer";

const { component, page } = defineProps<{ component: ContainerItem; page: Page }>();
const { desktopImage, mobileImage, altTag, link } = useImageContainer(page, component);
</script>
