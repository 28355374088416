<template>
  <div class="bg-charcoal-default">
    <section class="max-w-[1228px] mx-sm md:mx-auto py-lg">
      <Heading class="text-white"> Video </Heading>
      <LazySlider
        :space-between="24"
        :draggable="true"
        :autoplay="false"
        class="my-md mx-auto"
        :breakpoints="breakpoints"
        :items="videoIds"
      >
        <swiper-slide v-for="(video, index) in videosList" :key="index">
          <section class="relative">
            <NuxtImg :src="video.thumbnail" class="object-cover w-full" />

            <div
              class="cursor-pointer bg-white opacity-75 rounded-round p-sm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
              @click="showVideoPopup(video)"
            >
              <Icon name="triangle-right" :size="24" class="text-mkm-blue-light" />
            </div>
          </section>

          <section class="text-white mt-xs mb-2xs">
            <Text size="lg">
              {{ video.title }}
            </Text>

            <Text size="sm"> {{ video.views }} views | {{ video.date }} </Text>
          </section>
        </swiper-slide>
      </LazySlider>

      <NuxtLink :to="channelLink" v-if="channelLink" class="block mt-2xl w-fit mx-auto">
        <Button size="md" variant="primary"> See MKM Youtube Channel </Button>
      </NuxtLink>
    </section>
  </div>

  <div v-if="showPopup" class="fixed top-none w-full h-full z-50">
    <section class="absolute top-none w-full h-full bg-charcoal-darker opacity-80" />
    <section class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] md:w-[45%] h-1/2">
      <Icon
        name="times-thin"
        :size="24"
        class="text-white cursor-pointer absolute -top-xl sm:-top-md right-1/2 translate-x-1/2 sm:-right-xs"
        @click="showPopup = false"
      />
      <div v-html="currentVideo" class="w-full h-full video-frame" />
    </section>
  </div>
</template>

<script lang="ts" setup>
import { SwiperSlide } from "swiper/vue";
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useVideo } from "../../composables/UseContent/useVideo";
import { ref } from "vue";

const { component, page } = defineProps<{ component: Component; page: Page }>();
const { videoIds, channelLink, breakpoints } = useVideo(page, component);

const showPopup = ref(false);
const currentVideo = ref(null);
const videosList = ref([]) as any;

function showVideoPopup({ player }: any) {
  currentVideo.value = player;
  showPopup.value = true;
}

const { GOOGLE_YOUTUBE_DATA_API_KEY } = useRuntimeConfig().public;
const url = `https://youtube.googleapis.com/youtube/v3/videos?part=id,player,snippet,statistics&id=${videoIds.join(
  ",",
)}&maxResults=50&key=${GOOGLE_YOUTUBE_DATA_API_KEY}`;

await (async function fetchVideos() {
  try {
    const response = await fetch(url);
    const res = await response.json();

    if (!res.items) return;

    videosList.value = res.items.map(({ id, snippet, statistics, player }: any) => {
      return {
        id,
        thumbnail: snippet.thumbnails.high.url,
        title: snippet.title,
        views: statistics.viewCount,
        date: new Date(snippet.publishedAt).toLocaleDateString("en", {
          month: "long",
          day: "numeric",
          year: "numeric",
        }),
        player: player.embedHtml,
      };
    });
  } catch (error) {
    console.error("Network response was not ok", error);
  }
})();
</script>

<style>
.video-frame iframe {
  width: 100% !important;
  height: 100% !important;
  border: none;
}
</style>
