<template>
  <USPHeaderBar v-if="blocks" :items="blocks" />
</template>

<script lang="ts" setup>
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useHeaderBar } from "../../../composables/UseContent/useHeaderBar";

const { component, page } = defineProps<{ component: Component; page: Page }>();
const { blocks } = useHeaderBar(page, component);
</script>
